$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.MaintenancePage__Inner {
  @include isDesktop {
    padding: $spacing;
  }
}

.MaintenancePage__Lottie {
  max-width: 350px;
}