$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

$mainColumnWidth: 728px;

.MinimalLayout {
  .App__Main {
    margin: $spacing auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include isDesktop {
      margin: ($spacing * 2) auto;
      width: $mainColumnWidth;
    }
  }
}
