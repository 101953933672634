$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.Pod {
  &--gutter-full {
    .Pod__Content {
      margin-left: 1.8rem;
      margin-right: 1.8rem;
      width: auto;
    }
  }

  @include isMobileDevice {
    box-shadow: none !important;
    padding: 0;

    .Pod__Content {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.Pod__Subtitle {
  margin-top: -$marginMd0;
}

.Pod__Toolbar {
  margin-top: -7px;
  align-items: center;

  > * {
    margin-left: $spacing * 0.5;
  }
}

