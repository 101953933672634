$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

$appHeaderHeightMobile: 44px;
$appHeaderHeightDesktop: 80px;



// #region AppHeader

.AppHeader {
  position: relative;
  z-index: 4;

  > .Flex {
    height: $appHeaderHeightMobile;
    position: relative;
    user-select: none;
    z-index: 2;
  
    @include isDesktopDevice {
      height: $appHeaderHeightDesktop;
    }
  
    :global(a.--active) {
      font-weight: bold;
    }
  }
}

.AppHeader__Logo {
  font-size: 0;
  word-spacing: 0;

  &--desktop {
    > *:first-child {
      @include media('>=widescreen') {
        display: none !important;
      }
    }
  
    > *:last-child {
      @include media('<widescreen') {
        display: none !important;
      }
    }
  }
}

.AppHeader__Menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: calc(100vw - #{$appHeaderHeightMobile});
  height: calc(100vh - #{$appHeaderHeightMobile});
  z-index: 1;
  padding: $spacing * 0.5;
  pointer-events: none;
  transition-property: opacity, transform;
  transition-duration: 400ms;
  transition-timing-function: ease;

  @include isMobileDevice {
    transform: translateX(-100%) translateY(-100%);
  }

  @include isDesktopDevice {
    backdrop-filter: blur(1px);
    opacity: 0;
    transform: translateY(-100%);
  }

  &--open {
    pointer-events: auto;

    @include isMobileDevice {
      transform: translateX(0%) translateY(0%);
    }

    @include isDesktopDevice {
      opacity: 1;
      transform: translateY(0%);
    }

    + .AppHeader__MobileOverlay {
      opacity: 1;
    }
  }

  @include media('>=phoneSmall') {
    padding: $spacing;
  }

  @include isDesktopDevice {
    right: 0;
    width: auto;
    height: auto;
    padding: $spacing * 1.5;
  }
}

.AppHeader__GameTileImgContainer {
  min-width: 368px; // 4 game thumbnails @ 92px wide + margins
}

.AppHeader__NoGames {
  width: 100%;
  padding: $marginMd0 0;
  text-align: center;
}

// #endregion



// #region AppHeader - Mobile Menu

.AppHeader__MobileOverlay {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100vw;
  height: calc(100vh - #{$appHeaderHeightMobile});
  display: none;
  opacity: 0;
  pointer-events: none;
  backdrop-filter: blur(1px);
  transition-property: opacity, transform;
  transition-duration: 400ms;
  transition-timing-function: ease;

  @include isMobileDevice {
    display: block;
  }
}

.AppHeader__MobileNavBtn {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0 $spacing;
}

.MenuList li.AppHeader__MobileLink {
  display: flex;
  align-items: center;
  padding-top: $marginSm2;
  padding-bottom: $marginSm2;

  img {
    width: 28px;
    height: auto;
    margin-right: $marginSm2;
  }
}

// #endregion



// #region AppHeader - Desktop Menu

.AppHeader__DesktopHeader {
  > * {
    &:not(:last-child) {
      margin-right: $spacing;
      
      @include media('>=widescreen') {
        margin-right: $spacing * 1.5;
      }
    }
  }

  .SwSearch {
    &:first-child {
      display: none !important;

      @include media('>=desktop') {
        display: inline-flex !important;
      }
    }

    &:last-child {
      display: inline-flex !important;

      @include media('>=desktop') {
        display: none !important;
      }
    }
  }

  @include media('<desktop') {
    &--searchOpen {
      .AppHeader__DesktopHeaderLinkFlex {
        display: none !important;
      }

      .SwSearch {
        &:first-child {
          display: inline-flex !important;
        }
    
        &:last-child {
          display: none !important;
        }
      }
    }
  }
}

.AppHeader__DesktopHeaderLink {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  transition: text-shadow 240ms ease;
  padding: $marginSm3 0;
  font-size: 15px;
  white-space: nowrap;

  @include media('>=widescreen') {
    font-size: 1rem;
  }

  &:active {
    transform: translateY(1px);
  }

  .HeaderLinkCaret {
    margin-left: $marginSm2;
    transition: all 240ms ease;
  }

  &--active {
    .HeaderLinkCaret {
      transform: rotate(180deg);
    }
  }
}

.AppHeader__DesktopMenuLink {
  font-size: 80%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  transition: opacity 120ms ease;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: translateY(1px);
  }

  .Icon {
    max-height: 1rem;
    margin-right: $marginSm1;

    svg {
      height: 100%;
      width: auto;
    }
  }
}

// #endregion



// #region GenreIconButton

.GenreIconButtons {
  a {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 100px;
    transition: opacity 120ms ease;
    opacity: 1;
    user-select: none;
  
    &:hover {
      opacity: 0.7;
    }

    &:active {
      transform: translateY(1px);
    }

    img,
    svg {
      margin-bottom: $marginSm2;
      width: auto;
      height: 44px;
    }

    span {
      font-size: 80%;
      font-weight: bold;
      line-height: 1.1;
    }
  }
}

// #endregion



// #region UserStatus

.UserStatus__User,
.MobileUserStatus__User {
  p {
    font-weight: bold;

    .Icon {
      width: 1rem;
    }
  }
}

.UserStatus__User {
  a {
    cursor: pointer;
  }
  
  p {
    font-size: 80%;
    cursor: pointer;
  }

  .UserAvatar {
    cursor: pointer;
  }
}

// #endregion



// #region SwSearch

.SwSearch {
  .Button {
    padding-left: 1.75em;
    padding-right: 1.75em;
  }
}

// #endregion
